import React, { useEffect, useState } from "react";
import { Modal } from "antd";

// Import all app icons
import AppIcon1 from "../../../../images/app-icons/1.png";
import AppIcon2 from "../../../../images/app-icons/2.png";
import AppIcon3 from "../../../../images/app-icons/3.png";
import AppIcon4 from "../../../../images/app-icons/4.png";
import AppIcon5 from "../../../../images/app-icons/5.png";
import AppIcon6 from "../../../../images/app-icons/6.png";
import AppIcon7 from "../../../../images/app-icons/7.png";
import AppIcon8 from "../../../../images/app-icons/8.png";
import AppIcon9 from "../../../../images/app-icons/9.png";
import AppIcon10 from "../../../../images/app-icons/10.png";
import AppIcon11 from "../../../../images/app-icons/11.png";
import AppIcon12 from "../../../../images/app-icons/12.png";
import AppIcon13 from "../../../../images/app-icons/13.png";
import AppIcon14 from "../../../../images/app-icons/14.png";
import AppIcon15 from "../../../../images/app-icons/15.png";
import AppIcon16 from "../../../../images/app-icons/16.png";
import AppIcon17 from "../../../../images/app-icons/17.png";
import AppIcon18 from "../../../../images/app-icons/18.png";
import AppIcon19 from "../../../../images/app-icons/19.png";
import AppIcon20 from "../../../../images/app-icons/20.png";

const appIcons = [
  AppIcon1,
  AppIcon2,
  AppIcon3,
  AppIcon4,
  AppIcon5,
  AppIcon6,
  AppIcon7,
  AppIcon8,
  AppIcon9,
  AppIcon10,
  AppIcon11,
  AppIcon12,
  AppIcon13,
  AppIcon14,
  AppIcon15,
  AppIcon16,
  AppIcon17,
  AppIcon18,
  AppIcon19,
  AppIcon20,
];

const AppIconModal = ({
  showIconModal,
  handleModalOpen,
  handleSelectIcon,
  selectedAppIconIndex,
}) => {
  const [iconIndex, setIconIndex] = useState(selectedAppIconIndex);

  const handleOnOk = () => {
    handleSelectIcon(iconIndex);
    handleModalOpen(false);
  };

  useEffect(() => {
    setIconIndex(selectedAppIconIndex);
  }, [selectedAppIconIndex]);

  return (
    <Modal
      title="Select App Icon"
      open={showIconModal}
      onOk={handleOnOk}
      onCancel={() => handleModalOpen(false)}
    >
      <div className="grid-container">
        {appIcons.map((icon, index) => (
          <div
            key={index}
            className={`grid-item ${index === iconIndex && "selected-icon"}`}
            onClick={() => setIconIndex(index)}
          >
            <img
              alt={`app-icon-${index + 1}`}
              height={80}
              width={80}
              src={icon}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default AppIconModal;
