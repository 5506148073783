import { Radio } from "antd";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { connect } from "react-redux";
import ColorPicker from "../../commons/ColorPicker";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import html2canvas from "html2canvas";
import { db } from "../../../services/firebaseService/connection";
import { generateQR, rgbToHex } from "../../../helperFunctions/util";
import moment from "moment";
import ImageUploadButton from "../website/ImageUploaderButton";
import qrcode from "../../../images/ChantAppStoreQR_OneLink.png";
import { getPackages } from "../../../services/firebaseService/endPoints/admin/members";
import chantAssetImage from "../../../images/chant-assets.png";
import { updatePromote } from "../../../redux/actions/appData/promote";
import { doc, getDoc } from "@firebase/firestore";
import { getWebsiteConfig } from "../../../services/firebaseService/endPoints/admin/website";
import logger from "../../../utils/logger";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import {
  GUIDE_GROW,
  TOPIC_PROMOTE_GROUP,
} from "../../../constants/gettingStarted";
import {
  ChantBrandmark,
  PromoteBorder,
  PromoteBorderless,
} from "../../../utils/icons.jsx";
const SIZE = 190;

const TEMPLATE_SIZE = 67;

const Styles = {
  style1: "App",
  style2: "Landscape Photo",
  style3: "Text",
  empty: "Blank",
};

const TemplateQROptions = {
  app: "app",
  join: "join",
  group: "group",
  stadium: "stadium",
  chants: "chants",
};

const textColorPickerColors = [
  "rgb(97,189,109)",
  "rgb(26,188,156)",
  "rgb(84,172,210)",
  "rgb(44,130,201)",
  "rgb(147,101,184)",
  "rgb(71,85,119)",
  "rgb(204,204,204)",
  "rgb(65,168,95)",
  "rgb(0,168,133)",
  "rgb(61,142,185)",
  "rgb(41,105,176)",
  "rgb(85,57,130)",
  "rgb(40,50,78)",
  "rgb(0,0,0)",
  "rgb(247,218,100)",
  "rgb(251,160,38)",
  "rgb(235,107,86)",
  "rgb(226,80,65)",
  "rgb(163,143,132)",
  "rgb(239,239,239)",
  "rgb(255,255,255)",
  "rgb(250,197,28)",
  "rgb(243,121,52)",
  "rgb(209,72,65)",
  "rgb(184,49,47)",
  "rgb(124,112,107)",
  "rgb(209,213,216)",
];
const alpha = 1.0;

class Promotev2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStyle: Styles["style1"],
      hasBorders: true,
      backgroundColor: { r: "0", g: "0", b: "0", a: alpha },
      header: "",
      details: EditorState.createEmpty(),
      aspectRatio: 1,
      files: [],
      packages: [],
      colors: [
        "#000000",
        "#B80000",
        "#DB3E00",
        "#FCCB00",
        "#008B02",
        "#006B76",
        "#1273DE",
        "#004DCF",
        "#FFFFFF",
      ],
      svg: "",
      templateSvg: "",
      joinSvg: "",
      chantsSvg: "",
      stadiumSvg: "",
      isChantsPublished: false,
      isStadiumPublished: false,
      templateQR: "",
    };
    this.inputRef = null;
  }

  onRadioChange = (e) => {
    if (
      window.confirm("Warning: Changing templates will remove text and image")
    ) {
      this.setState({ selectedStyle: e.target.value, files: [] }, () => {
        let imageDiv = document.getElementById("promote-image");
        if (this.state.files.length > 0) {
          imageDiv.style.backgroundImage = "";
        }
      });

      this.props.dispatch(
        updatePromote({
          selectedStyle: e.target.value,
          files: [],
        })
      );
    }
  };

  handleCheckChange = (e) => {
    let hasBorders = e.target.checked;
    let aspectRatio = hasBorders ? 1 : 1.25;
    this.setState({
      hasBorders: hasBorders,
      aspectRatio: aspectRatio,
    });

    this.props.dispatch(
      updatePromote({
        hasBorders: hasBorders,
        aspectRatio: aspectRatio,
      })
    );
  };

  hexToRgbaObject(hex, alpha = 1.0) {
    hex = hex.replace("#", "");

    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    const r = parseInt(hex.substring(0, 2), 16).toString();
    const g = parseInt(hex.substring(2, 4), 16).toString();
    const b = parseInt(hex.substring(4, 6), 16).toString();

    return {
      r: r,
      g: g,
      b: b,
      a: alpha.toString(),
    };
  }

  handleColorChange = (color, type) => {
    this.setState({ [type]: color, updated: false });
    this.props.dispatch(updatePromote({ [type]: color }));
  };

  handleEditorStateChange = (state, name) => {
    // let editorState=RichUtils.toggleInlineStyle(state, 'HEADER')
    this.setState(
      {
        [name]: state,
      },
      () => {
        this.props.dispatch(updatePromote({ [name]: state }));
      }
    );
  };

  handleUpload = (files) => {
    let readFiles = [];

    files = Array.from(files);

    let event = new Event("loaddone");

    files.forEach((file) => {
      let reader = new FileReader();
      reader.addEventListener("loadend", (e) => {
        readFiles.push({ file: file, data: reader.result });
        if (readFiles.length === files.length) {
          document.dispatchEvent(event);
        }
      });
      reader.readAsDataURL(file);
    });
    document.addEventListener("loaddone", () => {
      this.setState({ files: readFiles }, () => {
        let imageDiv = document.getElementById("promote-image");
        if (this.state.files.length > 0) {
          imageDiv.style.backgroundImage = `url(${this.state.files[0].data})`;
        }
      });
      this.props.dispatch(updatePromote({ files: readFiles }));
    });
  };

  getLayout = (style) => {
    switch (style) {
      case Styles["style1"]:
        return (
          <div style={{ display: "flex", height: "100%" }}>
            <div className="col-md-7 mt-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: `${this.state.hasBorders ? "80px" : "10px"}`,
                }}
              >
                <div
                  id="promote-header"
                  style={{
                    textAlign: "center",
                    padding: "0% 10%",
                    maxWidth: "100%",
                    wordBreak: "break-word",
                    color: "#ffffff",
                    fontSize: 36,
                    fontWeight: "bolder",
                    fontFamily: "open sans",
                  }}
                >
                  {this.state.header}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  id="promote-details"
                  style={{
                    textAlign: "center",
                    padding: "0% 5%",
                    maxWidth: "100%",
                    wordBreak: "break-word",
                    color: "#ffffff",
                    fontFamily: "open sans",
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: draftToHtml(
                        convertToRaw(this.state.details.getCurrentContent())
                      ),
                    }}
                  ></span>
                </div>
              </div>
            </div>
            <div
              className="col-md-5"
              style={{ position: "relative", height: "100%" }}
            >
              <div
                className="d-flex"
                style={{
                  justifyContent: "center",
                  height: "auto",
                  padding: `${this.state.hasBorders ? "20" : "10"}px 20px 90px 0px`,
                }}
              >
                <div
                  className="col"
                  id="promote-image"
                  style={{
                    borderRadius: "10px",
                    aspectRatio: this.state.hasBorders ? "0.53" : "0.6",
                    backgroundSize: "cover",
                    boxShadow: "3px 3px 20px 2px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        );
      case Styles["style2"]:
        return (
          <div className="col mt-4">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: `${this.state.hasBorders ? "80px" : "10px"}`,
              }}
            >
              <div
                id="promote-header"
                style={{
                  textAlign: "center",
                  padding: "0% 12%",
                  maxWidth: "100%",
                  wordBreak: "break-word",
                  color: "#ffffff",
                  fontSize: 36,
                  fontWeight: "bolder",
                  fontFamily: "open sans",
                }}
              >
                {this.state.header}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                id="promote-image"
                className="py-2 px-5"
                style={{
                  width: "70%",
                  aspectRatio: "1.8",
                  backgroundSize: "cover",
                  boxShadow: "3px 3px 20px 2px",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                id="promote-details"
                style={{
                  textAlign: "center",
                  padding: "0% 12%",
                  maxWidth: "100%",
                  wordBreak: "break-word",
                  color: "#ffffff",
                  fontFamily: "open sans",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(
                      convertToRaw(this.state.details.getCurrentContent())
                    ),
                  }}
                ></span>
              </div>
            </div>
          </div>
        );
      case Styles["style3"]:
        return (
          <div className="col mt-4">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: `${this.state.hasBorders ? "80px" : "10px"}`,
              }}
            >
              <div
                id="promote-header"
                style={{
                  textAlign: "center",
                  padding: "0% 12%",
                  maxWidth: "100%",
                  wordBreak: "break-word",
                  color: "#ffffff",
                  fontSize: 36,
                  fontWeight: "bolder",
                  fontFamily: "open sans",
                }}
              >
                {this.state.header}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                id="promote-details"
                style={{
                  textAlign: "center",
                  padding: "0% 12%",
                  maxWidth: "100%",
                  wordBreak: "break-word",
                  color: "#ffffff",
                  fontFamily: "open sans",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(
                      convertToRaw(this.state.details.getCurrentContent())
                    ),
                  }}
                ></span>
              </div>
            </div>
          </div>
        );
      case Styles["empty"]:
        return <></>;
      default:
        return <></>;
    }
  };

  handleDownload = () => {
    html2canvas(document.getElementById("promote-downloadable-content"), {
      useCORS: true,
      width: 687,
    }).then((canvas) => {
      var link = document.createElement("a");
      link.download = `Group Promotion-${moment().format("MMM DD yyyy")}-.png`;
      link.href = canvas.toDataURL("image/png");
      link.click();

      this.props.dispatch(
        setGettingStartedItemStatus(
          this.props.currentGroup,
          GUIDE_GROW,
          TOPIC_PROMOTE_GROUP,
          true
        )
      );
    });
  };

  render() {
    const radioStyle = {
      // display: "block",
      height: "30px",
      lineHeight: "30px",
    };
    return (
      <div
        className="col"
        style={{
          backgroundColor: "#303030",
          marginTop: "1%",
          overflowY: "scroll",
        }}
      >
        <div style={{ display: "flex" }}>
          <div className=" pl-3 py-3" style={{ width: 687 }}>
            <div
              id="promote-downloadable-content"
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
                aspectRatio: this.state.aspectRatio.toLocaleString(),
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0%",
                  left: "0%",
                  width: "100%",
                  aspectRatio: this.state.aspectRatio.toLocaleString(),
                  zIndex: 1,
                  background: `rgba(${Object.values(this.state.backgroundColor)})`,
                }}
              ></div>
              <div
                className="background-promote"
                style={{
                  position: "absolute",
                  top: "0%",
                  left: "0%",
                  width: "100%",
                  aspectRatio: this.state.aspectRatio.toLocaleString(),
                  zIndex: 10,
                  background: `url(${this.state.hasBorders ? PromoteBorder : PromoteBorderless})`,
                }}
              ></div>
              {this.state.selectedStyle !== Styles["empty"] && (
                <div
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: "0%",
                    width: "100%",
                    height: "auto",
                    aspectRatio: this.state.aspectRatio.toLocaleString(),
                    zIndex: 20,
                  }}
                >
                  <div style={{ position: "relative", display: "contents" }}>
                    {this.getLayout(this.state.selectedStyle)}

                    <div
                      style={{
                        padding: this.state.hasBorders
                          ? "10px 20px 70px"
                          : "10px 20px",
                        position: "absolute",
                        bottom: "0px",
                        left: "0px",
                      }}
                    >
                      <img
                        width={100}
                        height={100}
                        src={this.props.groupLogo}
                        style={{ borderRadius: 100 }}
                      />
                    </div>
                    <div
                      style={{
                        padding: this.state.hasBorders
                          ? "10px 20px 70px"
                          : "10px 20px",
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                      }}
                    >
                      <div className="d-flex">
                        {this.state.templateQR === "" ? (
                          <></>
                        ) : this.state.templateQR === TemplateQROptions.app ? (
                          <img src={qrcode} style={{ width: 58 }} alt="" />
                        ) : (
                          <div
                            style={{
                              width: 58,
                              height: 58,
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `${this.state.templateSvg}`,
                                }}
                              ></span>
                            </div>
                          </div>
                        )}

                        <div className="col ml-2">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <img width={100} src={ChantBrandmark} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              style={{
                                color: "#ffffff",
                                fontWeight: "bold",
                                fontSize: 18,
                              }}
                            >
                              {"www.chant.fan"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-5 py-3 pr-3" style={{ padding: "1rem" }}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col">
                <div className="form-group">
                  <label className="promote-form-text-color promote-form-text-16 font-weight-bold">
                    Set Background Color
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {" "}
                      *{" "}
                    </span>
                  </label>
                  <div style={{ padding: "0rem 13px" }}>
                    <ColorPicker
                      color={this.state.backgroundColor}
                      colorType="backgroundColor"
                      handleColorChange={this.handleColorChange}
                      colorOptions={this.state.colors}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col">
                <div className="form-group">
                  <label className="promote-form-text-color promote-form-text-16 font-weight-bold">
                    Select Layout
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {" "}
                      *{" "}
                    </span>
                  </label>
                  <div
                    style={{
                      background: "#ffffff",
                      borderRadius: 5,
                      padding: "1rem",
                    }}
                  >
                    <Radio.Group
                      onChange={this.onRadioChange}
                      value={this.state.selectedStyle}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {Object.keys(Styles).map((style) => {
                        return (
                          <Radio style={radioStyle} value={Styles[style]}>
                            <span>{Styles[style]}</span>
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", padding: "1rem 0rem" }}>
              <div className="col">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  <input
                    style={{ height: "20px", width: "20px" }}
                    type="checkbox"
                    className="form-control-input cursor-pointer"
                    checked={this.state.hasBorders}
                    onChange={this.handleCheckChange}
                    tabIndex="2"
                  />
                  <span className="promote-form-text-color promote-form-text-16 ml-1 flex-center">
                    Show image stripes
                  </span>
                </div>
              </div>
            </div>

            {[Styles["style1"], Styles["style2"]].includes(
              this.state.selectedStyle
            ) && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col">
                  <div className="form-group">
                    <label className="promote-form-text-color promote-form-text-16 font-weight-bold">
                      {Styles["style1"] === this.state.selectedStyle
                        ? "Upload App screenshot"
                        : "Landscape Photo"}
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <div className={"col-12"}>
                      <ImageUploadButton
                        onDelete={(_) => {
                          this.setState({
                            files: [],
                          });
                          this.props.dispatch(updatePromote({ files: [] }));
                        }}
                        images={this.state.files}
                        aspectRatio={
                          this.state.selectedStyle === Styles["style1"]
                            ? this.state.hasBorders
                              ? 0.53
                              : 0.6
                            : 1.8
                        }
                        onSave={(images) => {
                          this.handleUpload(images);
                        }}
                      >
                        <div
                          className="px-2 py-1 promote-form-text-14 "
                          style={{ background: "#ffffff", cursor: "pointer" }}
                        >
                          Upload
                        </div>
                      </ImageUploadButton>
                      {/* <div className='row'>
                                            <div className='px-2 py-1 promote-form-text-14 ' style={{ background: '#ffffff', cursor: 'pointer' }} onClick={this.handleUploadButtonClick}>
                                                Upload
                                            </div>
                                        </div>
                                        <div>
                                            <input ref={ref => {
                                                this.inputRef = ref;
                                            }}
                                                type="file"
                                                style={{ height: 0, width: 0 }}
                                                onChange={this.handleUpload}
                                                onBlur={() => {
                                                    this.inputRef.blur();
                                                }}
                                            />
                                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col">
                <div className="form-group">
                  <label className="promote-form-text-color promote-form-text-16 font-weight-bold">
                    Header
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {" "}
                      *{" "}
                    </span>
                  </label>
                  <div className={"col-12"}>
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #ffffff",
                        background: "#ffffff",
                      }}
                    >
                      <input
                        style={{
                          border: "0px none",
                          outline: "none",
                          width: "100%",
                          background: "#303030",
                          color: "#ffffff",
                        }}
                        value={this.state.header}
                        onChange={(e) => {
                          this.setState({
                            header: e.target.value,
                          });
                          this.props.dispatch(
                            updatePromote({ header: e.target.value })
                          );
                        }}
                        display="col"
                        tabIndex="2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col">
                <div className="form-group promote-form-editor">
                  <label className="promote-form-text-color promote-form-text-16 font-weight-bold">
                    Enter Details
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {" "}
                      *{" "}
                    </span>
                  </label>
                  <div className={"col-12"}>
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #ffffff",
                        background: "#303030",
                      }}
                    >
                      <Editor
                        editorState={this.state.details}
                        wrapperStyle={{
                          width: "100%",
                          height: "100%",
                          // color: '#ffffff'
                        }}
                        onEditorStateChange={(state) =>
                          this.handleEditorStateChange(state, "details")
                        }
                        toolbar={{
                          options: [
                            "inline",
                            "fontSize",
                            // 'fontFamily',
                            "colorPicker",
                          ],
                          inline: {
                            inDropdown: false,
                            options: [
                              "bold",
                              // 'italic',
                              // 'superscript',
                              // 'subscript'
                            ],
                          },
                          fontSize: {
                            options: [10, 16, 20, 24, 30, 32, 36],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                          },
                          // fontFamily: {
                          //     options: ['Arial', 'Georgia', 'Impact', 'open sans', 'Tahoma', 'Times New Roman', 'Verdana'],
                          //     className: undefined,
                          //     component: undefined,
                          //     dropdownClassName: undefined,
                          // },
                          colorPicker: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            colors: textColorPickerColors,
                          },
                        }}
                        stripPastedStyles={true}
                        tabIndex="2"
                      />
                    </div>
                  </div>
                  <div>
                    <p style={{ fontSize: 13, color: "#ffffff" }}>
                      Shift + 2x Return to add space b/n lines.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  className="px-2 py-1 promote-form-text-color promote-form-text-14 font-weight-bold"
                  style={{
                    background: `rgb(204, 0, 0)`,
                    cursor: "pointer",
                  }}
                  onClick={this.handleDownload}
                >
                  Download
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ padding: "1rem" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="col-md-3" id="default-qr-code-promote">
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <img src={qrcode} style={{ width: 160 }} alt="" />
                <div className="flex-center" style={{ paddingRight: "42px" }}>
                  <input
                    style={{ height: "20px", width: "20px" }}
                    type="checkbox"
                    className="form-control-input cursor-pointer"
                    checked={this.state.templateQR === TemplateQROptions.app}
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({
                          templateQR: TemplateQROptions.app,
                        });
                      } else {
                        this.setState({
                          templateQR: "",
                          templateSvg: "",
                        });
                      }
                    }}
                    tabIndex="2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-7" style={{ color: "#ffffff" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <span style={{ fontSize: 18, fontWeight: "bold" }}>
                  App Download
                </span>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <p>
                  Use this general QR code for access to Chant in the app
                  stores.
                  <br />
                  Members must first be pre-registered (via Onboard options
                  #1-3) for group access.
                </p>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <button
                  className="btn btn-dark"
                  onClick={() =>
                    this.handleQRCodeDownload("default-qr-code-promote")
                  }
                >
                  Download
                </button>
              </div>
            </div>
          </div>

          {this.state.packages.length > 0 && (
            <div className="row mt-3">
              <div className="col-md-3" id="reg-qr-code-promote">
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      width: 160,
                      height: 160,
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {this.state.packages.length > 0 && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `${this.state.joinSvg}`,
                          }}
                        ></span>
                      )}
                    </div>
                  </div>

                  <div className="flex-center" style={{ paddingRight: "24px" }}>
                    <input
                      style={{ height: "20px", width: "20px" }}
                      type="checkbox"
                      className="form-control-input cursor-pointer"
                      checked={this.state.templateQR === TemplateQROptions.join}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let registrationLink = `https://group.chant.fan/${`${this.props.adminData.data.groupName || ""}`.toLowerCase().replace(" ", "")}/join`;
                          generateQR(
                            registrationLink,
                            this.props.groupLogo,
                            TEMPLATE_SIZE
                          ).then((joinSvg) => {
                            this.setState({
                              templateSvg: joinSvg,
                              templateQR: TemplateQROptions.join,
                            });
                          });
                        } else {
                          this.setState({
                            templateQR: "",
                            templateSvg: "",
                          });
                        }
                      }}
                      tabIndex="2"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7" style={{ color: "#ffffff" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    {" "}
                    Membership Packages
                  </span>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <p>
                    Use this QR code as a direct link to group's web
                    registration page (membership packages).
                  </p>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <button
                    className="btn btn-dark"
                    onClick={() =>
                      this.handleQRCodeDownload("reg-qr-code-promote")
                    }
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          )}

          {this.props.registrationLink && (
            <div className="row mt-3">
              <div className="col-md-3" id="reg-group-qr-code-promote">
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      width: 160,
                      height: 160,
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {this.props.registrationLink && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `${this.state.svg}`,
                          }}
                        ></span>
                      )}
                    </div>
                  </div>

                  <div className="flex-center" style={{ paddingRight: "24px" }}>
                    <input
                      style={{ height: "20px", width: "20px" }}
                      type="checkbox"
                      className="form-control-input cursor-pointer"
                      checked={
                        this.state.templateQR === TemplateQROptions.group
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          generateQR(
                            this.props.registrationLink,
                            this.props.groupLogo,
                            TEMPLATE_SIZE
                          ).then((joinSvg) => {
                            this.setState({
                              templateSvg: joinSvg,
                              templateQR: TemplateQROptions.group,
                            });
                          });
                        } else {
                          this.setState({
                            templateQR: "",
                            templateSvg: "",
                          });
                        }
                      }}
                      tabIndex="2"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7" style={{ color: "#ffffff" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    Group access (
                    {this.props.regCodeRole === "fan" ? "Non-member" : "Member"}
                    )
                  </span>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <p>
                    Provide group access with no pre-registration required
                    (anyone with code can enter group). Use Onboard option #4
                    and set access for Members (full app access) or Non-members
                    (restricted access to some app features).
                  </p>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <button
                    className="btn btn-dark"
                    onClick={() =>
                      this.handleQRCodeDownload("reg-group-qr-code-promote")
                    }
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.isStadiumPublished && (
            <div className="row mt-3">
              <div className="col-md-3" id="stadium-qr-code-promote">
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      width: 160,
                      height: 160,
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {this.state.isStadiumPublished && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `${this.state.stadiumSvg}`,
                          }}
                        ></span>
                      )}
                    </div>
                  </div>

                  <div className="flex-center" style={{ paddingRight: "24px" }}>
                    <input
                      style={{ height: "20px", width: "20px" }}
                      type="checkbox"
                      className="form-control-input cursor-pointer"
                      checked={
                        this.state.templateQR === TemplateQROptions.stadium
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let stadiumLink = `https://group.chant.fan/${`${this.props.adminData.data.groupName || ""}`.toLowerCase().replace(" ", "")}/stadium`;
                          generateQR(
                            stadiumLink,
                            this.props.groupLogo,
                            TEMPLATE_SIZE
                          ).then((stadiumSvg) => {
                            this.setState({
                              templateSvg: stadiumSvg,
                              templateQR: TemplateQROptions.stadium,
                            });
                          });
                        } else {
                          this.setState({
                            templateQR: "",
                            templateSvg: "",
                          });
                        }
                      }}
                      tabIndex="2"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7" style={{ color: "#ffffff" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    Stadium
                  </span>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <p>
                    Use this QR code as a direct link to group's stadium page.
                  </p>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <button
                    className="btn btn-dark"
                    onClick={() =>
                      this.handleQRCodeDownload("stadium-qr-code-promote")
                    }
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.props.isChantsPublished && (
            <div className="row mt-3">
              <div className="col-md-3" id="chants-qr-code-promote">
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      width: 160,
                      height: 160,
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {this.props.isChantsPublished && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `${this.state.chantsSvg}`,
                          }}
                        ></span>
                      )}
                    </div>
                  </div>

                  <div className="flex-center" style={{ paddingRight: "24px" }}>
                    <input
                      style={{ height: "20px", width: "20px" }}
                      type="checkbox"
                      className="form-control-input cursor-pointer"
                      checked={
                        this.state.templateQR === TemplateQROptions.chants
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let chantsLink = `https://group.chant.fan/${`${this.props.adminData.data.groupName || ""}`.toLowerCase().replace(" ", "")}/chants`;
                          generateQR(
                            chantsLink,
                            this.props.groupLogo,
                            TEMPLATE_SIZE
                          ).then((chantsSvg) => {
                            this.setState({
                              templateSvg: chantsSvg,
                              templateQR: TemplateQROptions.chants,
                            });
                          });
                        } else {
                          this.setState({
                            templateQR: "",
                            templateSvg: "",
                          });
                        }
                      }}
                      tabIndex="2"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7" style={{ color: "#ffffff" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    Chants
                  </span>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <p>
                    Use this QR code as a direct link to group's web chants
                    page.
                  </p>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <button
                    className="btn btn-dark"
                    onClick={() =>
                      this.handleQRCodeDownload("chants-qr-code-promote")
                    }
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-3">
            <div className="col-md-3" id="chant-assets">
              <img src={chantAssetImage} style={{ width: 160 }} alt="" />
            </div>
            <div className="col-md-7" style={{ color: "#ffffff" }}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <span style={{ fontSize: 18, fontWeight: "bold" }}>
                  Chant Brand Assets
                </span>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <p>
                  Red, White and Black Chant logos (PNG and JPG) for digital
                  promotion. Any use on physical product must be approved by
                  Chant (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:support@chant.fan"
                  >
                    support@chant.fan
                  </a>
                  )
                </p>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    // Using a direct path to the file in the public directory
                    const zipPath = "/images/chant-assets.zip";

                    fetch(zipPath)
                      .then((response) => {
                        if (!response.ok) {
                          throw new Error(
                            `HTTP error! Status: ${response.status}`
                          );
                        }
                        return response.blob();
                      })
                      .then((blob) => {
                        const url = window.URL.createObjectURL(
                          new Blob([blob], { type: "application/zip" })
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = "Chant-Assets.zip";
                        document.body.appendChild(link);
                        link.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(link);
                      })
                      .catch((error) => {
                        console.error("Error downloading zip file:", error);
                        alert(
                          "Failed to download the zip file. Please try again later."
                        );
                      });
                  }}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleQRCodeDownload = (id) => {
    html2canvas(document.getElementById(id)).then((canvas) => {
      let myImage = canvas.toDataURL("image/png");
      let url = "data:" + myImage;
      fetch(url).then((res) => {
        res.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "QRCode.png";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      });
    });
  };

  getClubColors = () => {
    getDoc(doc(db, "clubColors", this.props.clubId)).then((doc) => {
      if (doc.exists()) {
        let data = doc.data() || {};
        let colors = [];
        Object.keys(data).forEach((key) => {
          if (
            key.includes("color") &&
            data[key].r !== "" &&
            data[key].g !== "" &&
            data[key].b !== ""
          ) {
            let color = data[key];
            Object.keys(color).forEach((key) => {
              if (typeof color[key] === "string") {
                color[key] = parseInt(color[key]);
              }
            });
            let hexColor = rgbToHex(color.r, color.g, color.b);
            colors.push(hexColor);
          }
        });
        colors = Array.from(new Set([...colors, "#ffffff", "#000000"]));

        const bgColor = this.hexToRgbaObject(colors[0]);

        this.setState({ colors, backgroundColor: bgColor });
      }
    });
  };

  initState() {
    logger.log("promote data========>", this.props.promote);
    this.setState({
      ...this.props.promote,
    });
  }

  componentDidMount() {
    this.initState();
    this.getClubColors();
    generateQR(this.props.registrationLink, this.props.groupLogo, SIZE).then(
      (svg) => {
        this.setState({ svg });
      }
    );
    getPackages(this.props.currentGroup).then((packages) => {
      this.setState({ packages });
      if (packages.length > 0) {
        let registrationLink = `https://group.chant.fan/${`${this.props.adminData.data.groupName || ""}`.toLowerCase().replace(" ", "")}/join`;
        generateQR(registrationLink, this.props.groupLogo, SIZE).then(
          (joinSvg) => {
            this.setState({ joinSvg });
          }
        );
      }
    });
    if (this.props.isChantsPublished) {
      let chantsLink = `https://group.chant.fan/${`${this.props.adminData.data.groupName || ""}`.toLowerCase().replace(" ", "")}/chants`;
      generateQR(chantsLink, this.props.groupLogo, SIZE).then((chantsSvg) => {
        this.setState({ chantsSvg });
      });
    }
    getWebsiteConfig(this.props.currentGroup).then((website) => {
      if (website.stadium && website.stadium.isPublished) {
        let stadiumLink = `https://group.chant.fan/${`${this.props.adminData.data.groupName || ""}`.toLowerCase().replace(" ", "")}/stadium`;
        generateQR(stadiumLink, this.props.groupLogo, SIZE).then(
          (stadiumSvg) => {
            this.setState({ stadiumSvg, isStadiumPublished: true });
          }
        );
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.setState({
        templateQR: "",
      });
      this.getClubColors();
      generateQR(this.props.registrationLink, this.props.groupLogo, SIZE).then(
        (svg) => {
          this.setState({ svg });
        }
      );
      getPackages(this.props.currentGroup).then((packages) => {
        this.setState({ packages });
        if (packages.length > 0) {
          let registrationLink = `https://group.chant.fan/${`${this.props.adminData.data.groupName || ""}`.toLowerCase().replace(" ", "")}/join`;
          generateQR(registrationLink, this.props.groupLogo, SIZE).then(
            (joinSvg) => {
              this.setState({ joinSvg });
            }
          );
        }
      });
      if (this.props.isChantsPublished) {
        let chantsLink = `https://group.chant.fan/${`${this.props.adminData.data.groupName || ""}`.toLowerCase().replace(" ", "")}/chants`;
        generateQR(chantsLink, this.props.groupLogo, SIZE).then((chantsSvg) => {
          this.setState({ chantsSvg });
        });
      }
      getWebsiteConfig(this.props.currentGroup).then((website) => {
        if (website.stadium && website.stadium.isPublished) {
          let stadiumLink = `https://group.chant.fan/${`${this.props.adminData.data.groupName || ""}`.toLowerCase().replace(" ", "")}/stadium`;
          generateQR(stadiumLink, this.props.groupLogo, SIZE).then(
            (stadiumSvg) => {
              this.setState({ stadiumSvg, isStadiumPublished: true });
            }
          );
        }
      });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    registrationLink:
      state.adminData.allGroups[state.adminData.currentGroup].data
        .registrationLink,
    groupLogo:
      state.adminData.allGroups[state.adminData.currentGroup].data.configuration
        .logo,
    clubId: state.adminData.allGroups[state.adminData.currentGroup].data.clubId,
    regCodeRole:
      state.adminData.allGroups[state.adminData.currentGroup].data.regCodeRole,
    promote: state.promote,
    isChantsPublished:
      state.adminData.allGroups[state.adminData.currentGroup].data.configuration
        .isChantsPublished,
  };
};

export default connect(mapStateToProps)(Promotev2);
