export const STATUS_ALL_FILTER = "Status (all)";
export const MEMBERS_ALL_FILTER = "Members (all)";
export const MEMBERS_ON_CHANT_FILTER = "Members (on Chant)";
export const MEMBERS_NOT_ON_CHANT_FILTER = "Members (not on Chant)";
export const MEMBERS_ON_AUTO_RENEWAL_FILTER = "Members (auto-renewal)";
export const LEADERS_FILTER = "Leaders";
export const FANS_NON_MEMBERS_FILTER = "Fans (non-members)";
export const INACTIVE_FILTER = "Inactive";
export const LOCKED_ARCHIVED_FILTER = "Locked/Archived";

export const OTHER_FILTER = "Other";
export const OFFICIAL_CLUB_MEMBER_FILTER = "Official Club Member";
export const SEASON_TICKET_HOLDER_FILTER = "Season Ticket Holder";
export const VOLUNTEER_YES_FILTER = "Volunteer (yes)";
export const VOLUNTEER_NO_FILTER = "Volunteer (no)";
export const RECEIVE_EMAILS_YES_FILTER = "Receive emails (yes)";
export const RECEIVE_EMAILS_NO_FILTER = "Receive emails (no)";

export const PACKAGE_FILTER = "Package";
export const CHAPTER_FILTER = "Chapter";
