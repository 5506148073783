import React, { useState } from "react";
import ScreenHeader from "../../../../../CommonComponents/ScreenHeader";
import { Form, Input } from "antd";
import "./AdminPollCreate.css";
import ChantButton from "../../../../../CommonComponents/ChantButton/ChantButton";
import ChantCheckbox from "../../../../../CommonComponents/ChantCheckbox/ChantCheckbox";
import ImageUploadButton from "../../../website/ImageUploaderButton";
import {
  selectCurrentGroupData,
  selectUserDetails,
} from "../../../../../redux/selectors/adminData";
import { useSelector } from "react-redux";
import { db } from "../../../../../services/firebaseService/connection";
import { collection, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  fbUploadPollImage,
  sendPollNotification,
} from "../../../../../services/firebaseService/endPoints/admin/content/polls";
import logger from "../../../../../utils/logger";

const AdminPollCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [pollImageFile, setPollImageFile] = useState("");
  const userData = useSelector(selectUserDetails);

  const groupData = useSelector(selectCurrentGroupData);

  const [options, setOptions] = useState([
    { id: 1, name: "", votes: 0 },
    { id: 2, name: "", votes: 0 },
  ]);

  const handleRemoveOption = (selectedOption) => {
    const finalOptions = options
      .map((option) => {
        if (option.id > selectedOption.id) {
          return {
            ...option,
            id: option.id - 1,
          };
        }
        if (option.id < selectedOption.id) {
          return option;
        }
      })
      .filter((option) => option !== undefined);

    setOptions(finalOptions);
  };

  const handleOptionInput = (option, value) => {
    const optionsState = [...options];

    const selectedOptionIdx = optionsState.findIndex(
      (opt) => opt.id === option.id
    );

    if (selectedOptionIdx > -1) {
      optionsState[selectedOptionIdx].name = value;
    }

    setOptions(optionsState);

    // Clear error if both required options are filled
    if (optionsState[0]?.name && optionsState[1]?.name) {
      form.setFields([
        {
          name: "options",
          errors: undefined,
        },
      ]);
    }
  };

  const handleImgUpload = async (pollImageFile) => {
    try {
      if (!pollImageFile) {
        return;
      }

      const downloadUrl = await fbUploadPollImage(
        pollImageFile,
        groupData?.groupName,
        Math.floor(dayjs().valueOf() / 1000).toString()
      );

      return downloadUrl;
    } catch (err) {
      toast.error(
        "There was an error uploading image for the poll, could not complete the operation, contact admin"
      );
      return;
    }
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitLoading(true);
      // Validate that first two options are filled
      if (!options[0].name || !options[1].name) {
        form.setFields([
          {
            name: "options",
            errors: ["The first two options are required"],
          },
        ]);
        setSubmitLoading(false);
        return;
      }

      // Update poll duration validation to check if both are missing
      const days = values?.pollDuration?.days;
      const hours = values?.pollDuration?.hours;

      if ((!days || days === "") && (!hours || hours === "")) {
        form.setFields([
          {
            name: ["pollDuration", "days"],
            errors: ["Please enter days or hours"],
          },
        ]);
        setSubmitLoading(false);
        return;
      }

      // get banner image
      const downloadUrl = await handleImgUpload(pollImageFile);

      // get closes on
      const now = dayjs();
      const futureTime = now
        .add(Number(values?.pollDuration?.days || 0), "day")
        .add(Number(values?.pollDuration?.hours || 0), "hour");
      const timestampInSeconds = Math.floor(futureTime.valueOf() / 1000);

      //get options
      const optionsObj = options.reduce(
        (acc, curr) => {
          const optionKey = `op${curr.id}`;
          acc[optionKey] = curr;

          return acc;
        },
        { op1: null, op2: null, op3: null, op4: null, op5: null }
      );

      const body = {
        showEntriesToMembers: values?.showEntriesToMembers || false,
        provideFeedBack: values?.provideFeedBack || false,
        showForFans: values?.showForFans || false,
        desc: values?.question,
        isActive: true,
        isCancelled: false,
        noOfPolls: 0,
        createdByName: userData?.profile?.displayName,
        createdByDocId: userData?.profile?.uid,
        bannerImage: downloadUrl || "",
        closesOn: timestampInSeconds,
        addedOn: Math.floor(dayjs().valueOf() / 1000),
        ...optionsObj,
      };

      const pollsCollectionRef = collection(db, `polls/${groupData.id}/poll`);
      const docRef = await addDoc(pollsCollectionRef, body);
      const newPollId = docRef.id;

      sendPollNotification(
        groupData?.id,
        groupData.groupName,
        newPollId,
        values?.question
      );

      navigate("/admin/content/polls");

      toast.success("Poll created successfully!");
    } catch (error) {
      logger.error("Error submitting form:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div style={{ padding: "12px" }}>
      <ScreenHeader title="Create Poll" />

      <div className="admin-poll-create-container">
        <div className="form-section">
          <Form
            form={form}
            layout="vertical"
            className="create-poll-form"
            disabled={submitLoading}
            onFinish={handleSubmit}
            requiredMark={false}
          >
            <div>
              <Form.Item
                label={
                  <span className="form-label">
                    Question<span style={{ color: "#ff4d4f" }}> *</span>
                  </span>
                }
                name="question"
                rules={[{ required: true, message: "Please enter a question" }]}
              >
                <Input placeholder="Enter your question here" />
              </Form.Item>

              <Form.Item
                label={
                  <span className="form-label">Poll Image (Optional)</span>
                }
                name="image"
              >
                <ImageUploadButton
                  text="Upload"
                  images={pollImageFile ? [pollImageFile] : []}
                  onSave={(images) => {
                    setPollImageFile(images[0]);
                  }}
                />
              </Form.Item>

              <Form.Item
                label={<span className="form-label">Options</span>}
                name="options"
              >
                <div className="create-poll-options-container">
                  {options.map((option) => (
                    <div key={option.id}>
                      <p style={{ marginBottom: 0 }}>
                        {" "}
                        {`Option #${option.id}`}
                        {option.id <= 2 && (
                          <span style={{ color: "#ff4d4f" }}> *</span>
                        )}
                      </p>

                      <div className="create-poll-option">
                        <Input
                          value={option.name}
                          style={{ width: "40rem" }}
                          placeholder={`Option #${option.id}`}
                          onChange={(e) =>
                            handleOptionInput(option, e.target.value)
                          }
                        />
                        {option.id > 2 && (
                          <ChantButton
                            style={{ marginBottom: 0 }}
                            onClick={() => handleRemoveOption(option)}
                          >
                            ╳
                          </ChantButton>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {options.length < 5 && (
                  <ChantButton
                    style={{
                      marginTop: "12px",
                    }}
                    size="small"
                    onClick={() => {
                      setOptions((options) => [
                        ...options,
                        { id: options.length + 1, name: "", votes: 0 },
                      ]);
                    }}
                  >
                    + Add Option
                  </ChantButton>
                )}
              </Form.Item>

              <div style={{ paddingTop: "16px" }}>
                <Form.Item name="provideFeedBack" valuePropName="checked">
                  <ChantCheckbox>
                    Provide fields for members to enter additional feedback (max
                    150 characters)
                  </ChantCheckbox>
                </Form.Item>

                <Form.Item name="showEntriesToMembers" valuePropName="checked">
                  <ChantCheckbox>
                    Show individual responses to all members. If not selected,
                    only admin/leaders can see
                  </ChantCheckbox>
                </Form.Item>

                {groupData?.regCodeRole === "fan" && (
                  <Form.Item name="showForFans" valuePropName="checked">
                    <ChantCheckbox>
                      Allow fans to see poll and vote?
                    </ChantCheckbox>
                  </Form.Item>
                )}
              </div>

              <Form.Item
                label={
                  <span className="form-label">
                    Poll Duration
                    <span style={{ color: "#ff4d4f" }}> *</span>
                  </span>
                }
              >
                <div
                  style={{ display: "flex", gap: "12px", alignItems: "center" }}
                >
                  <Form.Item
                    name={["pollDuration", "days"]}
                    noStyle
                    dependencies={[["pollDuration", "hours"]]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const hours = getFieldValue([
                            "pollDuration",
                            "hours",
                          ]);
                          if (
                            (!value || value === "") &&
                            (!hours || hours === "")
                          ) {
                            return Promise.reject("Please enter days or hours");
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      type="number"
                      min={0}
                      style={{ width: "140px" }}
                      placeholder="Days"
                      addonAfter="days"
                    />
                  </Form.Item>
                  <Form.Item name={["pollDuration", "hours"]} noStyle>
                    <Input
                      type="number"
                      min={0}
                      style={{ width: "140px" }}
                      placeholder="Hours"
                      addonAfter="hours"
                    />
                  </Form.Item>
                </div>
              </Form.Item>

              <div className="flex-center" style={{ width: "100%" }}>
                <Form.Item>
                  <ChantButton
                    type="primary"
                    htmlType="submit"
                    loading={submitLoading}
                    style={{ marginTop: "24px" }}
                  >
                    Publish Poll
                  </ChantButton>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AdminPollCreate;
